import { useState, useEffect, useMemo } from 'react'
import { ChainId, Currency } from 'gdcswap_sdk'
import { useActiveWeb3React } from './index'

const useWNATIVE = () => {
  const { chainId } = useActiveWeb3React()
  const chainId1: any = chainId
  const [ETHER, setETHER] = useState<Currency>(Currency.ENATIVE[chainId1 as ChainId])
  useEffect(() => {
    setETHER(Currency.ENATIVE[chainId as ChainId])
  }, [chainId])

  return { ETHER }
}
export default useWNATIVE

export const getWNATIVE = (chainId: ChainId) => {
  return Currency.ENATIVE[chainId as ChainId]
}

export const useViewText = () => {
  const { chainId } = useActiveWeb3React()
  const strConfig = useMemo<{ [chainId in ChainId]?: string }>(() => {
    return {
      [ChainId.MAINNET]: 'BscScan',
      [ChainId.BSCTESTNET]: 'BscScan',
      [ChainId.OKEX]: 'OKExChain',
      [ChainId.OKEX_TESTNET]: 'OKExChain',
      [ChainId.BCCMAINNET]: 'BCCChain',
      [ChainId.BCCTESTNET]: 'BCCChain'
    }
  }, [])
  const [str, setStr] = useState('BCC')
  useEffect(() => {
    setStr(`View on ${strConfig[chainId as ChainId]}`)
  }, [chainId, strConfig])
  return str
}
