import { TokenInfo } from '@uniswap/token-lists'

// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = 'tokens.uniswap.eth'

export const DEFAULT_LIST_OF_LISTS: string[] = [
  // DEFAULT_TOKEN_LIST_URL,
  // 't2crtokens.eth', // kleros
  // 'tokens.1inch.eth', // 1inch
  // 'synths.snx.eth',
  // 'tokenlist.dharma.eth',
  // 'defi.cmc.eth',
  // 'erc20.cmc.eth',
  // 'stablecoin.cmc.eth',
  // 'tokenlist.zerion.eth',
  // 'tokenlist.aave.eth',
  // 'https://www.coingecko.com/tokens_list/uniswap/defi_100/v_0_0_0.json',
  // 'https://app.tryroll.com/tokens.json',
  'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json'
  // 'https://defiprime.com/defiprime.tokenlist.json',
  // 'https://umaproject.org/uma.tokenlist.json'
]

export const DEFAULT_CHAINID_TOKEN_LIST: TokenInfo[] = [
  {
    name: 'USDT Token',
    symbol: 'USDT',
    address: '0xD0bc8E86cd94190CFD1ac2BeDeb9Aa303bdc905b',
    chainId: 3020,
    decimals: 18,
    logoURI: 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_USDT.svg'
  }
  // {
  //   name: 'USDT Token',
  //   symbol: 'USDT',
  //   address: '0x9b58AEb1411422F5AbCbEaD236614bBb1320c20C',
  //   chainId: 4429,
  //   decimals: 18,
  //   logoURI: 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_USDT.svg'
  // }
]
