import { ChainId } from 'gdcswap_sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.SEPOLIA]: '0x32ef9CB208F70Ba9AF20067a009e6E85E33AFDAD',
  // [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.BCCTESTNET]: '0xc36f109EA8b2b8B5d8C4407308f19Be2814B7a13',
  [ChainId.BCCMAINNET]: '0xED926d25521DA2639b9FfFf88985bbE29cbc4CaB',
  [ChainId.BSCMAINNET]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  [ChainId.BSCTESTNET]: '0x301907b5835a2d723Fe3e9E8C5Bc5375d5c1236A',
  [ChainId.OKEX]: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
  [ChainId.OKEX_TESTNET]: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
