import { Currency, Token, ChainId } from 'gdcswap_sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import useWNATIVE from '../../hooks/useWNATIVE'
import { useActiveWeb3React } from '../../hooks'
// import EthereumLogo from '../../assets/images/ethereum-logo.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'

const getTokenLogoURL = (address: string) =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo) <{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  const { chainId } = useActiveWeb3React()
  const { ETHER } = useWNATIVE()
  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }

      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations, ETHER])

  if (currency === ETHER) {
    const ETHER_NAME: { [chainId in ChainId]?: string } = {
      [ChainId.BSCTESTNET]: 'bnb',
      [ChainId.MAINNET]: 'ETH',
      [ChainId.BSCMAINNET]: 'bnb',
      [ChainId.OKEX]: 'okt',
      [ChainId.OKEX_TESTNET]: 'okt',
      [ChainId.BCCMAINNET]: 'BCC',
      [ChainId.BCCTESTNET]: 'BCC'
    }
    const ETHER_LOGO = ETHER_NAME[chainId as ChainId] || 'eth'
    return <StyledEthereumLogo src={`/images/${ETHER_LOGO}.png`} size={size} style={style} />
  }

  // if (currency?.symbol && ['GS'].includes(currency.symbol)) {
  //   return <StyledEthereumLogo src={`/images/${currency.symbol}.png`} size={size} style={style} />
  // }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
